@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
html {height: 100%;}
body {height: 100%;}

.wrapper{
  /* display: flex; */
}
.wrapper .static-txt{
  color: #fff;
  font-size: 60px;
  font-weight: 400;
}
.wrapper .dynamic-txts{
  /* margin-left: 15px; */
  margin-left: -15px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: #a11522;
  font-size: 50px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) infinite;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li span{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li span::after{
  content: "";
  position: absolute;
  left: 0;
  height: 110%;
  width: 100%;
  background: #22232a;
  border-left: 2px solid #a11522;
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: 100%;
  }
  100%{
    left: 0;
  }
}

@media screen and (max-width: 883px) {

    /* .containerPhoto {
        display:none;
        width: 262px;
        height: 350px;
    } */
/* 
    .homeContainer{
      display: flex;
      justify-content: flex-end;
      flex-direction: column-reverse;
      align-items: center;
    }
    .h-screen{
      height: auto !important;
    } */
    /* .homeText{
        width: 600px;
    } */

}

.home__blob-img {
  width: 360px;
}

/* .containerPhoto{
  mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMTkuMjAxLCAyMjUuNzkzLCAxNjYuMzA5LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDQ1LjM2MywgMjA1LjY5MywgNTYuMzM4LCAxKSIgb2Zmc2V0PSIxMDAlIj48L3N0b3A+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICAgICAgICAgICAgICAgICAgPC9kZWZzPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0idXJsKCNzdy1ncmFkaWVudCkiIGQ9Ik0xMy43LC0yMi45QzE4LjgsLTIwLjksMjQuNSwtMTkuMiwyOS43LC0xNS40QzM1LC0xMS42LDM5LjcsLTUuOCwzOC4yLC0wLjhDMzYuOCw0LjEsMjkuMSw4LjMsMjUsMTRDMjAuOSwxOS43LDIwLjQsMjcsMTYuOCwzMC41QzEzLjIsMzQuMSw2LjYsMzMuOCwwLjMsMzMuMkMtNiwzMi43LC0xMS45LDMxLjgsLTE4LjUsMzAuMUMtMjUuMiwyOC4zLC0zMi41LDI1LjYsLTM2LjcsMjAuNUMtNDEsMTUuMywtNDIuMSw3LjcsLTQwLjYsMC45Qy0zOSwtNS45LC0zNC45LC0xMS44LC0zMC4yLC0xNi4yQy0yNS42LC0yMC42LC0yMC40LC0yMy42LC0xNS4zLC0yNS41Qy0xMC4yLC0yNy41LC01LjEsLTI4LjYsLTAuNCwtMjcuOUM0LjMsLTI3LjMsOC43LC0yNC45LDEzLjcsLTIyLjlaIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MCA1MCkiIHN0cm9rZS13aWR0aD0iMCIgc3R5bGU9InRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UgMHM7Ij48L3BhdGg+CiAgICAgICAgICAgICAgPC9zdmc+);
  -webkit-mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMTkuMjAxLCAyMjUuNzkzLCAxNjYuMzA5LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDQ1LjM2MywgMjA1LjY5MywgNTYuMzM4LCAxKSIgb2Zmc2V0PSIxMDAlIj48L3N0b3A+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICAgICAgICAgICAgICAgICAgPC9kZWZzPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0idXJsKCNzdy1ncmFkaWVudCkiIGQ9Ik0xMy43LC0yMi45QzE4LjgsLTIwLjksMjQuNSwtMTkuMiwyOS43LC0xNS40QzM1LC0xMS42LDM5LjcsLTUuOCwzOC4yLC0wLjhDMzYuOCw0LjEsMjkuMSw4LjMsMjUsMTRDMjAuOSwxOS43LDIwLjQsMjcsMTYuOCwzMC41QzEzLjIsMzQuMSw2LjYsMzMuOCwwLjMsMzMuMkMtNiwzMi43LC0xMS45LDMxLjgsLTE4LjUsMzAuMUMtMjUuMiwyOC4zLC0zMi41LDI1LjYsLTM2LjcsMjAuNUMtNDEsMTUuMywtNDIuMSw3LjcsLTQwLjYsMC45Qy0zOSwtNS45LC0zNC45LC0xMS44LC0zMC4yLC0xNi4yQy0yNS42LC0yMC42LC0yMC40LC0yMy42LC0xNS4zLC0yNS41Qy0xMC4yLC0yNy41LC01LjEsLTI4LjYsLTAuNCwtMjcuOUM0LjMsLTI3LjMsOC43LC0yNC45LDEzLjcsLTIyLjlaIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MCA1MCkiIHN0cm9rZS13aWR0aD0iMCIgc3R5bGU9InRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UgMHM7Ij48L3BhdGg+CiAgICAgICAgICAgICAgPC9zdmc+);
} */

.image{
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgIDxkZWZzPiAKICAgICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0ic3ctZ3JhZGllbnQiIHgxPSIwIiB4Mj0iMSIgeTE9IjEiIHkyPSIwIj4KICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9IiNhNDA0MTQiIG9mZnNldD0iMCUiPjwvc3RvcD4KICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDIiIHN0b3AtY29sb3I9IiM2ZTBkMTciIG9mZnNldD0iNjAlIj48L3N0b3A+CiAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICA8L2RlZnM+CiAgPHBhdGggZmlsbD0idXJsKCNzdy1ncmFkaWVudCkiIGQ9Ik0xMy43LC0yMi45QzE4LjgsLTIwLjksMjQuNSwtMTkuMiwyOS43LC0xNS40QzM1LC0xMS42LDM5LjcsLTUuOCwzOC4yLC0wLjhDMzYuOCw0LjEsMjkuMSw4LjMsMjUsMTRDMjAuOSwxOS43LDIwLjQsMjcsMTYuOCwzMC41QzEzLjIsMzQuMSw2LjYsMzMuOCwwLjMsMzMuMkMtNiwzMi43LC0xMS45LDMxLjgsLTE4LjUsMzAuMUMtMjUuMiwyOC4zLC0zMi41LDI1LjYsLTM2LjcsMjAuNUMtNDEsMTUuMywtNDIuMSw3LjcsLTQwLjYsMC45Qy0zOSwtNS45LC0zNC45LC0xMS44LC0zMC4yLC0xNi4yQy0yNS42LC0yMC42LC0yMC40LC0yMy42LC0xNS4zLC0yNS41Qy0xMC4yLC0yNy41LC01LjEsLTI4LjYsLTAuNCwtMjcuOUM0LjMsLTI3LjMsOC43LC0yNC45LDEzLjcsLTIyLjlaIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MCA1MCkiIHN0cm9rZS13aWR0aD0iMCIgc3R5bGU9InRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UgMHM7Ij48L3BhdGg+Cjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(0) brightness(-57);
}



.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 800px; */
}

.timeline {
  height: 300px;
  width: 5px;
  background-color: #e5e5e5;
}

.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #eae4e4;
}

.stub2 {
  height: 1000px;
}

.circle {
  width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* background-color: #e5e5e5; */
    font-weight: bolder;
    color: rgb(34 35 42 / var(--tw-bg-opacity));
    /* margin-left: 240px;*/
}

.circleWrapper {
  position: relative;
  display: contents;
}

.message {
  /* position: absolute; */
  top: 10%;
  left: 200%;
  min-width: 150px;
  font-weight: bold;
  margin-left: 20px;
  display: contents;
}

#homeWrapper{
  display: contents !important;
}

.deutscheLogo{
  width: 40px;
}

@keyframes color-animation {
  0%    {color: var(--color-1)}
  32%   {color: var(--color-1)}
  33%   {color: var(--color-2)}
  65%   {color: var(--color-2)}
  66%   {color: var(--color-3)}
  99%   {color: var(--color-3)}
  100%  {color: var(--color-1)}
}

@media screen and (max-width: 883px) {

  .containerPhoto {
    display: table-header-group;
    text-align: center;
    
  }
  .homeContainer{
    justify-content: center;
    /* display: table; */
    flex-direction: column-reverse;
  }


  .image{
    height: 285px;
    display: inline;
  }
/* 
  .homeContainer{
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: center;
  }
  .h-screen{
    height: auto !important;
  } */
  /* .homeText{
      width: 600px;
  } */

}

@media screen and (max-width: 880px) {

  .mercedesFoto{
    margin-left: 75px !important;
  }

  #about{
    /* height: 305vh; */
    height: auto;
  }

  #home{
    height: auto;
    padding-bottom: 30px;
  }

  .skills__subcontainer{
    display: inline;
  }

  .imgsContainer{
    display: inline-block;
  }

  .tsystemsLogo{
    margin-top: 0 !important;
    margin-bottom: 20px;
    margin-left: 50px;
  }

  .mercedesLogo{
    margin-bottom: 30px;
  }

  #spline{
    width: 300px !important;
    height: auto !important;
  }
  
  #work{
    height: auto !important;
  }

 

  .auto-type{
    font-size: 1.9rem !important;
  }

  .typed-cursor{
    font-size: 1.9rem !important;
  }



  /* SWIPER CSS */

  /*
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #2d2f39;
    /* margin-top: 50px; */

    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    height: auto !important;
  }

  .swiper-wrapper {
    height: 100vh !important;
  }

  .swiper {
    margin-left: auto;
    margin-right: auto;
  }

  .mySwiper{
    width: 100% !important;
    margin-top: 30px;
  }

  .swiper-pagination {
    margin-bottom: 30px !important;
  }

  .swiper-pagination {
    position: inherit !important;
  }



  .swiper-wrapper {
    height: 80vh !important;
  }

  .swiper-slide {
      height: 500px !important;
    }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -10px !important;
    position: inherit !important;
  } */








@media screen and (max-width: 1200px) {

  /* #timelineP{
    margin-left: 150px;
    margin-right: 150px;
  } */

}
  /* #etec{
    position: 'static'; 
    margin-top: '10px'
  } */
}


.textWrapper{
}




:root{
  /* FONT */
  --font: 'Poppins', sans-serif;;

  /* COLORS */
  --bg-color: #151320;
  /* --conic-gradient: conic-gradient(
    #FF2E2E,
    #FF831E,
    #FCFF51,
    #58FF57,
    #575FFF,
    #D257FF,
    #FF57E5,
    #FF1556
  ); */

    --conic-gradient: conic-gradient(
      #3498db,
      #1abc9c,
      #2ecc71,
      #f39c12,
      #9b59b6,
      #8e44ad
  );


}


/* ---------- BUTTON --------- */
.btn{
  width: 12em;
  height: 5em;
  border-radius: 1em;
  box-shadow: 0 1em 1em rgba(0,0,0, .5);

  position: relative;

  display: flex !important;
  border-radius: 10px !important;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  cursor: pointer;

  transition: transform .3s ease;
}

.btn::before{
  content: '';
  position: absolute;
  width: 110%;
  height: 350%;
  background: var(--conic-gradient);

  animation: spin 1.5s infinite linear;
}

.btn::after{
  content: var(--content);
  position: absolute;
  background-color: var(--bg-color);
  width: 96%;
  height: 86%;
  border-radius: .2em;

  font-size: 1.4rem;
  color: #fff;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover{
  transform: scale(1.1);
}

@keyframes spin {
  to{
    transform: rotate(360deg);
  }
}






#arrow{
  display: inline;

}



.navbarTitle{
  margin: 0;
  line-height: 1;
  font-size: 2em;
  color: transparent;
  text-transform: uppercase;
  background-image: linear-gradient(to left, #2ecc71, #3498db, #a11522,#9b59b6);
  -webkit-background-clip: text;
  animation: animate 5s linear infinite;
  -webkit-background-size: 500%;
  background-size: 500%;
  text-align: center;
}

@keyframes animate{
  0%{
    background-position: 0 100%;
  }
  50%{
    background-position: 100% 0;
  }
  100%{
    background-position: 0 100%;
  }
}


#leftContact {
  position: absolute;
  top: 0;
}

#rightContact {
  width: 100px;
  align-self: flex-end;
}

#topContact {
  position: absolute;
  top: 0;
}

#bottomContact {
  position: absolute;
  bottom: 0;
}

.contactDiv{
  display: inline-block;
  padding: 1rem;
}



.skills__container{
  row-gap: 0;
}

.skills__header{
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-left: 15px;
  cursor: pointer;
}

.skills__icon, .skills__arrow{
  font-size: 2rem;
  color: #a11522;
}

.skills__icon{
  margin-right: 0.75rem;
}

.skills__title{
  font-size: 2rem !important;
}

.skills__subtitle{
  font-size: 1.5rem;
  color: #f0f0f0;
}

.skills__arrow{
  margin-left: auto;
  transition: .4s;
}


.skills__list{
  row-gap: 1.5rem;
  /* padding-left: 2.7rem; */
}

.skills__titles{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.skills__name{
  font-size: 1.1rem;
  font-weight: 600;
}

.skills__bar, .skills__percentage{
  height: 5px;
  border-radius: .25rem;
}

.skills__bar{
  background-color: rgb(23 23 28 / var(--tw-bg-opacity))
}

.skills__percentage{
  display: block;
  background-image: linear-gradient(to right, #b32439,#f39c12, #3498db, #2ecc71);
  /* background-image: linear-gradient(to left, #2ecc71, #3498db, #9b59b6, #a11522,); */
  animation: progress 0.4s ease-in-out forwards;
}

.skills__html{
  width: 90%;
}

.skills__css{
  width: 80%;
}

.skills__js{
  width: 95%;
}


/* PORCENTAGENS */

.skills__html10{
  width: 10%;
}

.skills__css20{
  width: 20%;
}

.skills__js30{
  width: 30%;
}

.skills__html40{
  width: 40%;
}

.skills__50{
  width: 50%;
}

.skills__60{
  width: 60%;
}

.skills__65{
  width: 65%;
}

.skills__70{
  width: 70%;
}

.skills__80{
  width: 80%;
}

.skills__85{
  width: 85%;
}

.skills__90{
  width: 90%;
}

.skills__100{
  width: 100%;
}



.skills__close .skills__list{
  height: 0;
  overflow: hidden;
}

.skills__open .skills__list{
  height: max-content;
  margin-bottom: 2.5rem;
}

.skills__open .skills__arrow{
  transform: rotate(-180deg);
}

.skills__container{
  /* grid-template-columns: repeat(4, 1fr); */
  display: block;
}

.grid {
  display: flow-root !important;
}

.skills__data{
  margin: 20px;
}





/* --- Start progress bar --- */

.step{
  margin-bottom: 90px;
}

.process-wrapper {
	margin:auto;
	max-width:1080px;
}

#progress-bar-container {
	position:relative;
	width:90%;
	margin:auto;
	height:100px;
	margin-top:135px;
}

#progress-bar-container ul {
	padding:0;
	margin:0;
	/* padding-top:15px; */
	z-index:2;
	position:absolute;
	width:100%;
	margin-top:-60px
}

#progress-bar-container li:before {
	content:" ";
	display:block;
	margin:auto;
	width:30px;
	height:30px;
	border-radius:50%;
	border:solid 2px #aaa;
	transition:all ease 0.3s;
     
}

#progress-bar-container li.active:before, #progress-bar-container li:hover:before {
	border:solid 2px #fff;
       	 
	/* background: linear-gradient(to left, #2ecc71, #e2134f); */
  background: linear-gradient(to left, #e2134f, #a11522);
}

#progress-bar-container li {
	list-style:none;
	float:left;
  
	width:15%;
	text-align:center;
	color:#f0f0f0;
	text-transform:uppercase;
	font-size:14px;
	cursor:pointer;
	font-weight:700;
	transition:all ease 0.2s;
	vertical-align:bottom;
	height:60px;
	position:relative;
  margin-left: 10px;
}

#progress-bar-container li .step-inner {
	position:inherit;
	width:100%;
	bottom:-30px;
  font-size: 16px;
}

#progress-bar-container li.active, #progress-bar-container li:hover {
	color:#666;
}

/* #progress-bar-container li:after {
	content:" ";
	display:block;
	width:6px;
	height:6px;
	background:#777;
	margin:auto;
	border:solid 7px #fff;
	border-radius:50%;
	margin-top:60px;
	box-shadow:0 2px 13px -1px rgba(0,0,0,0.3);
	transition:all ease 0.2s;
     
} */

#progress-bar-container li:hover:after {
	background:#555;
}

#progress-bar-container li.active:after {
	background:#207893;
}

#progress-bar-container #line {
	width:100%;
	margin:auto;
	background: #eee;
	height:6px;
	position:absolute;
	/* left:10%; */
	top:57px;
	z-index:1;
	border-radius:50px;
	transition:all ease 0.9s;
}

#progress-bar-container #line-progress {
	content:" ";
	width:3%;
	height:100%;
	background: #207893;	 
	background: linear-gradient(to left, #2ecc71, #3498db); 
	position:absolute;
	z-index:2;
	border-radius:50px;
	transition:all ease 0.9s;
}

#progress-content-section {
	width:90%;
	margin: auto;
	background: #f3f3f3;
	border-radius: 4px;
}

#progress-content-section .section-content {
	padding:30px 40px;
	text-align:center;
}

#progress-content-section .section-content h2 {
	font-size:17px;
	text-transform:uppercase;
	color:#333;
	letter-spacing:1px;
  font-weight: bold !important;
}

#progress-content-section .section-content p {
	font-size:16px;
	line-height:1.8em;
	color: #595959;
}

#progress-content-section .section-content {
	display:none;
	animation: FadeInUp 700ms ease 1;
	animation-fill-mode:forwards;
	transform:translateY(15px);
	opacity:0;
  background-color: #d1dbdc;
}

#progress-content-section .section-content.active {
	display:block;
}

@keyframes FadeInUp {
	0% {
		transform:translateY(15px);
		opacity:0;
	}
	
	100% {
		transform:translateY(0px);
		opacity:1;
	}
}

.timelineUL{
  color: #595959;
}

.averageLink{
text-decoration: none;
}
.averageLink:hover{
  text-decoration: none;
  color: #a11522;
  }

#navbarDefault{
  background-color: #17171c !important;
}

#spline{
  display: flex !important;
  width: 300px ;
  height: auto;
}

.splaineContainer{
}


.contratosVideo{
  width: 500px;
  height: auto;
}

.auto-type{
  color: #a11522;
  font-size: 2.2rem;
}

.typed-cursor{
  color: #fff;
  font-size: 2.2rem;
}




#progress-content-section .section-content.active {
  text-align: justify;
}

.contents{
  text-align: justify;
}

#tagcloud{
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;

  width: 100% !important;
}

#cloudSVG{
  width: 100% !important;
}


.aws-btn {
  --button-default-height: 48px !important; 
  --button-default-font-size: 14px !important;
  --button-default-border-radius: 6px !important;
  --button-horizontal-padding: 20px !important;
  --button-raise-level: 5px !important;
  --button-hover-pressure: 2 !important;
  --transform-speed: 0.185s !important;
  --button-primary-color: #49536f !important;
  --button-primary-color-dark: #2b3041 !important;
  --button-primary-color-light: #d4d8e3 !important;
  --button-primary-color-hover: #444d67 !important;
  --button-primary-color-active: #3d455d !important;
  --button-primary-border: 2px solid #2b3041 !important;
  --button-secondary-color: #fefc81 !important;
  --button-secondary-color-dark: #cbc802;
  --button-secondary-color-light: #7f7d01;
  --button-secondary-color-hover: #fefb53;
  --button-secondary-color-active: #fdfa1c;
  --button-secondary-border: 2px solid #cbc802;
  --button-anchor-color: #eccab1;
  --button-anchor-color-dark: #6d4b29;
  --button-anchor-color-light: #48321b;
  --button-anchor-color-hover: #e9c2a5;
  --button-anchor-color-active: #e4b490;
  --button-anchor-border: 2px solid #6d4b29;
  --button-danger-color: #3db64b;
  --button-danger-color-dark: #d2e054;
  --button-danger-color-light: #ffffff;
  --button-danger-color-hover: #3aae48;
  --button-danger-color-active: #37a343;
  --button-danger-border: 2px solid #d2e054;
}


 .navbarOption{
  text-decoration: none;
  color: #d1d5db;
}

.navbarOption {
  position: relative;
  display: inline-block;
  padding: 10px;
}

.navbarOption::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #a11522;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.navbarOption:hover::after {
  transform: scaleX(1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbarOption:hover {
  color: #a11522;
  animation: fadeIn 0.2s ease-in-out;
}


.swiperContainer{
  width: 40vw;
  /* height: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30vw;
  /* margin-top: 300px; */
}


.swiper {
  /* width: 60vw;
  height: 60vh; */
  width: auto;
  height: auto
}

@media (max-width: 768px) {
  .swiper {
    /* width: 90vw;
    height: 90vh;
    margin-left: -45vw;
    margin-top: -45vh; */
    
    width: auto;
    height: auto;
  }

  .canvasContainer{
    width: 100vw !important;
    height: 50vh !important
  }

  .swiperContainer{
    width: auto;
    height: auto;
    margin-left: 0 !important;
  }

 
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100% !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-slide .canvasContainer {
  display: block;
  width: 100%;
} 

Canvas{
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
}

.canvasContainer{
  width: 100vw;
  height: auto
}

.portfolioTitles{
  font-family: 'Poppins';
  font-weight: 100;
}

.swiper {
  /* width: 90vw;
  height: 90vh;
  margin-left: -45vw;
  margin-top: -45vh; */
  
  width: auto;
  height: auto;
}

.canvasContainer{
  width: 100vw !important;
  height: 50vh !important;
}

.swiperContainer{
  width: auto;
  height: auto;
  margin-left: 0 !important;
}